import useSWR from 'swr';
import Router from 'next/router';
import firebase, { db, auth, storage } from '@/firebase/clientApp';
import dayjs from 'dayjs';
import customizeAxios from '@/lib/customizeAxios';
import * as cf from '@/lib/clientFunction';
import * as Sentry from '@sentry/nextjs';

// useSWR用のfetch(黄)
export const fetcher = (url) =>
  fetch(url).then(async (res) => {
    const result = await res.json();

    if (res.status >= 500 && res.status < 600) {
      if (process.env.NODE_ENV === 'production') {
        Router.push('/systemerror');
      } else {
        alert('ページ遷移！500');
      }
      return null;
    } else if (res.status === 401) {
      if (process.env.NODE_ENV === 'production') {
        Router.push('/sessionerror');
      } else {
        alert('ページ遷移！401');
        Router.push('/sessionerror');
      }
      return null;
    } else if (res.status === 400 || (res.status >= 402 && res.status < 500)) {
      return Promise.reject(result);
    } else {
      return result;
    }
  });

// Sentryへシステムエラーの送信
export const errorFunction = (logMessage, error) => {
  process.env.NODE_ENV !== 'production' && console.error(logMessage + error);
  Sentry.captureException(logMessage + error);
};

/////////////////// 共通 ///////////////////
// firebaseを使ってログインしているか確認
export const useLogin = async (data) => {
  try {
    // APIでトークンの発行
    const result = await customizeAxios({
      method: 'post',
      url: '/api/login',
      data: data,
    });
    if (result.status === 400) {
      return { status: result.status, message: result.message };
    } else if (result.status !== 200) {
      throw new Error(result.message); //500エラー含む
    } else if (result.status === 200) {
      // フロントからauthへログイン
      const userCredential = await auth.signInWithCustomToken(result.data.token);
      return { status: 200, data: userCredential.user.uid };
    }
  } catch (error) {
    errorFunction('useLoginログインエラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// // ログアウト
export const useLogout = async () => {
  try {
    await auth.signOut();
    return { status: 200, data: true };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return {
      status: 500,
      message:
        'システムエラーが発生しました。管理者へエラーの通知を送信したため、しばらく経ってから再度表示してください',
    };
  }
};

// エラーテスト用のお知らせ取得
export const useGetError = async () => {
  try {
    throw new Error('理事会システムエラーテスト本番用');

    return { status: 200, data: 'error' };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return {
      status: 500,
      message:
        'システムエラーが発生しました。管理者へエラーの通知を送信したため、しばらく経ってから再度表示してください',
    };
  }
};

// 添付ファイルのURLを取得
export const useGetFile = async (name) => {
  try {
    const path = `images/directors/${name}`;
    const storageRef = storage.ref().child(path);
    const url = await storageRef.getDownloadURL();
    return { status: 200, data: url };
  } catch (error) {
    errorFunction('添付ファイルの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// トップページ用の更新履歴の取得
export const useGetArticlesLimit = async () => {
  console.log('!');
  try {
    const today = cf.getToday(); // 今日の日付
    const snapshot = await db
      .collection('directors_articles')
      .where('active', '==', true)
      .where('view_flg', '==', true)
      .where('view_date', '<=', today)
      .orderBy('view_date', 'desc')
      .limit(5)
      .get();

    // const snapshot2 = snapshot.collection('directors_articles').where('main', '!=', 'next');
    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      if (doc.data().main != 'next') {
        result.push({
          id: doc.id, // DocumentID
          title: doc.data().title, // 件名
          view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
          main: doc.data().main,
          sub: doc.data().sub,
          main_Japanese: doc.data().main_Japanese,
        });
      }
    });
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// 更新履歴一覧の取得
export const useGetArticlesAll = async () => {
  try {
    const today = cf.getToday(); // 今日の日付
    const snapshot = await db
      .collection('directors_articles')
      .where('active', '==', true)
      .where('view_flg', '==', true)
      .where('view_date', '<=', today)
      .orderBy('view_date', 'desc')
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      if (doc.data().main != 'next') {
        result.push({
          id: doc.id, // DocumentID
          title: doc.data().title, // 件名
          view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
          main: doc.data().main, // メインカテゴリの英語名
          sub: doc.data().sub, // サブカテゴリの英語名
          main_Japanese: doc.data().main_Japanese, // メインカテゴリの日本語名
        });
      }
    });
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// カテゴリ、id から記事の取得
export const useGetArticle = async (main, sub, id) => {
  try {
    // URLが正しくない場合は空で返す
    if (!id || !main || !sub) return { status: 200, data: {} };

    const doc = await db.collection('directors_articles').doc(id).get();

    // データがないときは空で返す
    if (!doc.exists) {
      return { status: 200, data: {} };
    }

    // 取得したデータの格納
    const data = doc.data();
    // activeと公開日がfalse、またはカテゴリが相違の場合は空で返す
    if (
      !data ||
      data.active === false ||
      data.view_flg === false ||
      data.main !== main ||
      data.sub !== sub
    ) {
      return { status: 200, data: {} };
    }
    // view_date（公開日）が未来の場合は空で返す
    if (dayjs(doc.data().view_date.toDate()).isAfter()) {
      return { status: 200, data: {} };
    }
    // データがあったら返す
    const result = {
      id: doc.id,
      title: data.title, // タイトル
      date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
      contents: data.contents, // 本文
      main: data.main,
      files: [],
    };
    console.log(data.files);
    // file情報があったら
    if (data.files.length >= 1) {
      // seqがある場合はseqで並び替えをする
      if (data.files[0].seq) {
        //先頭のファイルにseqが存在した場合
        console.log('seqがあり', data.files[0].seq);
        data.files = data.files.sort((a, b) => a.seq - b.seq); //seq順でソートする
        console.log('seqがあり', data.files);
      }
      result.files = data.files.map((file) => {
        return {
          file_name: file.file_name,
          storage_name: file.storage_name,
          seq: file.seq,
        };
      });
    }
    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// カテゴリから記事一覧の取得
export const useGetArticlesList = async (main, sub) => {
  try {
    const result = {}; // 取得するデータの格納
    result.category = []; // カテゴリを格納
    result.list = []; // 記事一覧を格納

    const today = cf.getToday(); // 今日の日付

    // URLが正しくない場合は空で返す
    if (!main || !sub) return { status: 200, data: [] };

    // カテゴリが正しいか確認
    const snapshot1 = await db
      .collection('directors_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    snapshot1.forEach(function (doc) {
      result.category.push({
        Japanese_name: doc.data().Japanese_name,
        sub_categories: doc.data().sub_categories.find((val) => val.name === sub),
      });
    });

    // メインカテゴリがなかったら空で返す
    if (result.category.length !== 1) return { status: 200, data: [] };
    // サブカテゴリがなかったら空で返す
    if (result.category.length >= 1 && result.category[0].sub_categories === undefined) {
      return { status: 200, data: [] };
    }

    // カテゴリから記事一覧を取得
    const snapshot2 = await db
      .collection('directors_articles')
      .where('main', '==', main)
      .where('sub', '==', sub)
      .where('active', '==', true)
      .where('view_flg', '==', true)
      .where('view_date', '<=', today)
      .orderBy('view_date', 'desc')
      .get();

    snapshot2.forEach(function (doc) {
      result.list.push({
        id: doc.id, // DocumentID
        title: doc.data().title, // 件名
        view_date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
        main: doc.data().main, // メインカテゴリの英語名
        sub: doc.data().sub, // サブカテゴリの英語名
        main_Japanese: doc.data().main_Japanese, // メインカテゴリの日本語名
        sub_Japanese: doc.data().main_Japanese, // サブカテゴリの日本語名
      });
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// カテゴリの取得
export const useGetCategories = async (main) => {
  try {
    const today = cf.getToday(); // 今日の日付
    // URLが正しくない場合は空で返す
    if (!main) return { status: 200, data: [] };

    const snapshot = await db
      .collection('directors_categories')
      .where('name', '==', main)
      .where('active', '==', true)
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      if (typeof doc.data().sub_categories[0].sub_seq !== 'undefined') {
        console.log(
          'sub_seqがある',
          doc.data().Japanese_name,
          doc.data().sub_categories[0].sub_seq
        );
        const sortedSubCategores = doc.data().sub_categories.sort((a, b) => a.sub_seq - b.sub_seq);
        console.log('sub_seqがある', sortedSubCategores);
        result.push({
          name: doc.data().name, // メインの英語名
          Japanese_name: doc.data().Japanese_name, // メインの日本語名
          sub_categories: sortedSubCategores, // サブカテゴリの情報
        });
      } else {
        throw new Error('サブカテゴリのseq未登録エラー');
      }
      //   result.push({
      //     name: doc.data().name, // メインの英語名
      //     Japanese_name: doc.data().Japanese_name, // メインの日本語名
      //     sub_categories: doc.data().sub_categories, // サブカテゴリの情報
      //   });
    });

    // サブカテゴリの登録が無い場合はシステムエラー
    if (result.length >= 1 && result[0].sub_categories.length === 0) {
      throw new Error('サブカテゴリの未登録エラー');
    }

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};

// カテゴリ、id から記事の取得
export const useGetArticleNext = async () => {
  try {
    const today = cf.getToday(); // 今日の日付
    const snapshot = await db
      .collection('directors_articles')
      .where('main', '==', 'next')
      .where('sub', '==', 'next')
      .where('active', '==', true)
      .where('view_flg', '==', true)
      .where('view_date', '<=', today)
      .orderBy('view_date', 'desc')
      .limit(1)
      .get();

    // 取得したデータの格納
    const result = [];
    snapshot.forEach(function (doc) {
      result.push({
        title: doc.data().title, // 件名
        date: dayjs(doc.data().view_date.toDate()).format('YYYY.MM.DD'), // 公開日
        contents: doc.data().contents, // 本文
      });
    });

    return { status: 200, data: result };
  } catch (error) {
    errorFunction('データの取得エラーです', error);
    return { status: 500, message: 'システムエラーが発生しました。' };
  }
};
